<template>
  <div class="animated fadeIn">
    <b-card no-body>
      <b-container fluid class="pt-3">
        <b-row class="pt-3">
          <b-col>
            <filtering-panel
              ref="filteringPanel"
              mode="server"
              dataset-name="reports-manager"
              :load-dictionaries="loadDictionaries"
              :loaded="filteringPanel.loaded"
              :show-labels="false"
              :hide-custom-filters="true"
              :filters="filteringPanel.filters"
              @dict-loaded="onFilteringPanelLoad"
              @search="getData"
              @change="filterData"
              @custom-filter-selected="onCustomFilterSelected"
              @state-changed="onFilteringPanelStateChange"
            />
          </b-col>
        </b-row>
        <b-row v-show="dataTable.visible">
          <b-col>
            <reports-manager-table ref="reports-manager-table" />
          </b-col>
        </b-row>
      </b-container>
    </b-card>
  </div>
</template>

<script>
import FilteringPanel from "@/components/FilteringPanel";

import ReportsManagerTable from "./ReportsManagerTable";

export default {
  name: "ReportsManager",
  components: {
    FilteringPanel,
    ReportsManagerTable
  },
  data: function() {
    return {
      filteringPanel: {
        selected: {},
        loaded: false,
        filters: [
          {
            type: "select",
            dataType: "string",
            title: "Category",
            tooltip: "Report categories",
            name: "category",
            trackby: "id",
            label: "label",
            multiple: true,
            options: [],
            selected: {}
          },
          {
            type: "select",
            dataType: "string",
            title: "Department",
            tooltip: "Departments",
            name: "department",
            trackby: "id",
            label: "label",
            multiple: true,
            options: [],
            selected: {}
          },
          {
            type: "select",
            dataType: "string",
            title: "Status",
            tooltip: "Report status",
            name: "status",
            trackby: "id",
            label: "label",
            multiple: true,
            options: [
              { id: 0, label: "Active" },
              { id: 1, label: "Deleted" }
            ],
            selected: {}
          }
        ]
      },
      dataTable: {
        visible: true
      }
    };
  },
  computed: {},
  mounted() {},
  methods: {
    getData() {
      //let self = this;

      let fp = this.$refs.filteringPanel;

      if (!fp.isSearchAllowed()) return;

      this.dataTable.isLoading = true;

      this.dataTable.dataSet = [];

      let f = fp.selected;

      let payload = {
        category: f.category ? f.category.map(i => i.id) : [],
        department: f.department ? f.department.map(i => i.id) : [],
        deleted: f.status ? f.status.map(i => i.id) : []
      };

      /*
            if (fp.panelState.isCustomFiltersBar) {
                payload.expression = JSON.stringify(fp.prepareExpressions(f['custom-filter']))
            }
*/
      this.$refs["reports-manager-table"].getData(payload);
    },
    async loadDictionaries() {
      let self = this;

      const categories = async () => {
        let response = await self.$api.get("users/reports/categories/all");

        let categories = response.map(u => ({
          id: u.id,
          label: u.name
        }));
        self.filteringPanel.filters.find(
          f => f.name === "category"
        ).options = categories;
      };
      const departments = async () => {
        let response = await self.$api.get("users/reports/departments/all");

        let departments = response.map(u => ({
          id: u.id,
          label: u.name
        }));
        self.filteringPanel.filters.find(
          f => f.name === "department"
        ).options = departments;
      };

      await Promise.all([categories(), departments()]).then(() => {
        self.filteringPanel.loaded = true;
      });
    },
    filterData() {},
    onFilteringPanelLoad() {
      this.getData();
    },
    onCustomFilterSelected() {},
    onFilteringPanelStateChange() {}
  },
  watch: {}
};
</script>

<style></style>
