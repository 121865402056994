<template>
  <div class="animated fadeIn">
    <table-custom
      ref="dataTable"
      :name="`${$customTable.getCustomTableName($route.meta.module)}`"
      :loading="dataTable.isLoading"
      :data="dataTable.dataSet"
      :options="dataTable.options"
    >
      <div slot="afterFilter">
        <b-row>
          <b-col>
            <b-button-group>
              <b-button
                variant="outline-dark"
                title="Add Report"
                @click="addItem()"
              >
                <font-awesome-icon icon="plus" /> Create
              </b-button>
            </b-button-group>
          </b-col>
        </b-row>
      </div>
      <div slot="custom-actions" slot-scope="props">
        <div class="btn-group">
          <b-button
            size="sm"
            variant="primary"
            title="View"
            @click="viewItem(props.row)"
          >
            <font-awesome-icon icon="eye" />
          </b-button>

          <b-button
            size="sm"
            variant="info"
            title="Edit"
            @click="editItem(props.row)"
          >
            <font-awesome-icon icon="pencil-alt" />
          </b-button>

          <b-button
            size="sm"
            v-if="props.row.Deleted === '0'"
            variant="danger"
            title="Delete"
            @click="deleteItem(props.row)"
          >
            <font-awesome-icon icon="trash" />
          </b-button>

          <b-button
            size="sm"
            v-if="props.row.Deleted === '1'"
            class="btn btn-warning btn-sm"
            title="Restore"
            @click="restoreItem(props.row)"
          >
            <font-awesome-icon icon="trash-restore" />
          </b-button>
        </div>
      </div>
    </table-custom>
  </div>
</template>

<script>
import Vue from "vue";
import TableCustom from "@/components/TableCustom";

export default {
  name: "ReportsManagerTable",
  components: {
    TableCustom
  },
  data: function() {
    return {
      rawData: {},
      dataTable: {
        //loading: false,
        isLoading: false,
        dataSet: [],
        options: {
          columns: [
            "ID",
            "Category",
            "Frequency",
            "Department",
            "Responsibles",
            "Reviewers",
            "Actions"
          ],
          filterable: [
            "ID",
            "Category",
            "Frequency",
            "Department",
            "Responsibles",
            "Reviewers"
          ],
          footerHeadings: false,
          perPage: 50,
          showCustomActions: true
        }
      }
    };
  },
  computed: {},
  mounted() {},
  methods: {
    onFilter() {},

    async getData(payload) {
      let self = this;

      this.dataTable.isLoading = true;

      //  this.dataTable.dataSet = [];

      let response = [];

      try {
        response = await this.$api.post("users/reports/manager", payload);

        self.dataTable.isLoading = false;

        if (response.length === 0) return;

        response.forEach(row => {
          row.isSynchronizing = false;
        });

        self.dataTable.dataSet = response;

        self.$emit("loaded", self.dataTable.dataSet.length);
      } catch (error) {
        console.log(error);

        self.dataTable.isLoading = false;

        self.$form.msgBoxOk("Error occured");
      }
    },
    addItem: function() {
      this.$router.push({
        name: "Report category submission",
        params: {
          action: "create"
        }
      });
    },
    viewItem: function(row) {
      this.$router.push({
        name: "Report category submission",
        params: {
          action: "view",
          id: row.ID
        }
      });
    },
    editItem: function(row) {
      this.$router.push({
        name: "Report category submission",
        params: {
          action: "edit",
          id: row.ID
        }
      });
    },
    async restoreItem(row) {
      let item = this.dataTable.dataSet.find(item => item.ID === row.ID);

      let confirm = await this.$form.showConfirmation(
        `Report '${item.Category}' will be restored. Do you want to proceed?`
      );

      if (!confirm) return;

      let self = this;

      let payload = {
        deleted: 0
        //data: JSON.stringify({ deleted: 0 })
      };

      this.$api
        .put(`users/reports/manager/${item.ID}`, payload)
        .then(response => {
          self.$form.makeToastInfo(response.message);

          self.getData();
        })
        .catch(response => {
          console.log(response);

          self.$form.makeToastError(response.message);
        });
    },
    async deleteItem(row) {
      let item = this.dataTable.dataSet.find(item => item.ID === row.ID);

      let confirm = await this.$form.showConfirmation(
        `Report '${item.Category}' will be deleted. Do you want to proceed?`
      );

      if (!confirm) return;

      let self = this;

      this.$api
        .delete(`users/reports/manager/${item.ID}`)
        .then(response => {
          self.$form.makeToastInfo(response.message);

          self.getData();
        })
        .catch(response => {
          console.log(response);

          self.$form.makeToastError(response.message);
        });
    },
    async syncItem(row) {
      let self = this;

      let listIdx = self.dataTable.dataSet.findIndex(
        item => item.ID === row.ID
      );

      let list = self.dataTable.dataSet[listIdx];

      list.isSynchronizing = true;

      this.$api
        .get(`phplist/${row.ID}/sync`)
        .then(response => {
          self.$form.makeToastInfo(response.message);
        })
        .catch(response => {
          console.log(response);

          self.$form.makeToastError("Error occured");
        })
        .finally(() => {
          list.isSynchronizing = false;

          Vue.set(self.dataTable.dataSet, listIdx, list);
        });
    }
  },
  watch: {}
};
</script>

<style scoped></style>
